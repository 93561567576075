@charset 'UTF-8';
@import './setting/variables';

.GPC0127 {
	max-width:(1284px+60px);
	padding-left:30px;
	padding-right:30px;
	@include screen(custom, max, $max-sm) {
		padding:0 24px
	}
	.title-wrap {
		width:100%;
		padding:13px 30px 0;
		max-width:(1284px+60px);
		margin:0 auto 44px;
		@extend %clearfix;
		@include screen(custom, max, ($max-lg)) {
			padding-top:15px;
		}
		@include screen(custom, max, ($max-sm)) {
			padding:33px 0 0;
			margin-bottom:35px;
		}
		.inner {
			max-width:800px;
		}
		&.align-left {
			.inner {
				float:left;
				text-align:left;
			}
		}
		&.align-right {
			.inner {
				float:right;
				text-align:right;
			}
		}
		&.align-center {
			.inner {
				margin:0 auto;
				text-align:center;
			}
		}
		.title, .title h1, .title h2, .title h3, .title h4, .title h5, .title h6 {
			color:$color-black;
			font-size:48px;
			line-height:56px;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-lg) {
				font-size:44px;
				line-height:52px;
			}
			@include screen(custom, max, $max-md) {
				font-size:40px;
				line-height:48px;
			}
			@include screen(custom, max, ($max-sm)) {
				font-size:32px;
				line-height:36px;
			}
			@include screen(custom, max, 374) {
				font-size:26px;
				line-height:30px;
			}
		}
		.title {
			color:$color-black;
			max-width:1284px;
			margin:0 auto;
		}
	}
	.heading-box {
		max-width:800px;
		margin:0 auto 44px;
		text-align:center;
		@include screen(custom, max, $max-sm) {
			margin-bottom:25px;
		}
		.title {
			color:$color-black;
			font-size:48px;
			line-height:56px;
			@include font-family($font-semibold);
			@include screen(custom, max, $max-lg) {
				font-size:44px;
				line-height:52px;
			}
			@include screen(custom, max, $max-md) {
				font-size:40px;
				line-height:48px;
			}
			@include screen(custom, max, ($max-sm)) {
				font-size:32px;
				line-height:36px;
			}
			@include screen(custom, max, 374) {
				font-size:26px;
				line-height:30px;
			}
		}
		.desc {
			margin-top:15px;
			font-size:18px;
			line-height:24px;
			color:$color-nightrider;
			@include screen(custom, max, $max-sm) {
				margin-top:7px;
				font-size:16px;
				line-height:24px;
			}
		}
	}
	.notice-detail {
		margin-bottom: 30px;
		border-top: 1px solid $line-gray;
		border-bottom: 1px solid $line-gray;
	}
	.btn-area {
		text-align: right;
		[dir="rtl"] & {
			@include screen(custom, min, $min-md) {
				text-align:left;
			}
		}
		@include screen(custom, max, $max-sm) {
			text-align: center;
		}
	}

	.title-area {
		position: relative;
		padding: 35px 30px 35px 30px;
		border-bottom: 1px solid $line-lightgray;
		@include screen(custom, max, $max-sm) {
			padding:25px 0;
		}
		.title {
			font-size:24px;
			line-height:32px;
			@include font-family($font-semibold);
			color:$color-black;
			text-align: left;
			[dir="rtl"] & {
				text-align:right;
			}
			@include screen(custom, max, $max-sm) {
				font-size:20px;
				line-height:26px;
			}
		}
	 }
	.control-area {
		position:absolute;
		right:31px;
		bottom:20px;
		text-align:right;
		font-size:0;
		[dir="rtl"] & {
			right:auto;
			left:31px;
		}
		@include screen(custom, max, $max-sm) {
			position:static;
			text-align:left;
			[dir="rtl"] & {
				text-align:right;
			}
		}
		.control {
			display:inline-block;
			.control-menu {
				display:inline-block;
				position:relative;
				margin:0 0 0 22px;
				padding:0 0 0 30px;
				[dir="rtl"] & {
					padding:0 30px 0 0 ;
				}
				[dir="rtl"] & {
					@include screen(custom, min, $min-md) {
						margin:0 22px 0 0;
					}
				}
				@include font-family($font-bold);
				font-size:14px;
				line-height:20px;
				@include screen(custom, max, $max-sm) {
					margin:0 15px 0 0;
					[dir="rtl"] & {
						margin:0 0 0 15px;
					}
				}
				> a {
					display:block;
					color:$color-dimgray;
					text-transform:uppercase;
					text-decoration:none;
				}
				&.print {
					@include screen(custom, max, $max-sm) {
						display:none;
					}
				}
			}
			.download-sets {
				@mixin set-position-x{
					left:50%;
					transform:translateX(-50%);
					-ms-transform:translateX(-50%);
					-moz-transform:translateX(-50%);
					-o-transform:translateX(-50%);
				}
				position: relative;
				cursor: pointer;
				.download-sets-wrap {
					display:none;
					position: absolute;
					top:38px; left:0;
					padding:18px 25px 15px;
					background-color:$bg-white;
					border:1px solid $line-gray;
					border-radius:2px;
					box-shadow:1px 1px 5px 1px hsla(0, 0%, 0%, 0.05);
					text-align:left;
					@include screen(custom, min, $min-md){
						margin-left:14px;
						@include set-position-x;
					}
					[dir="rtl"] & {
						text-align:right;
						@include screen(custom, min, $min-md){
							margin-left:0;
							margin-right:14px;
						}
						@include screen(custom, max, $max-sm) {
							left:auto;
							right:0;
						}
					}
					&.active {
						display:block;
						z-index: 1;
						// 20200520 START 이상현 - downlaod 레이어 팝업이 프린팅되는 것 방지함. 
						@media print {
							display:none;
						}
						// 20200520 END
					}
					&:before {
						content:"";
						width:25px;
						height:13px;
						background : {
							image:url(/lg5-common-gp/images/components/popover-mark-above.png);
							repeat:no-repeat;
							position:50% 50%;
						}
						position:absolute;
						top:-12px; left:56px;
						@include screen(custom, min, $min-md){
							@include set-position-x;
						}
						[dir="rtl"] & {
							@include screen(custom, max, $max-sm) {
								left:auto;
								right:56px;
							}
						}
					}
				}
				.set-name {
					display:block;
					color:$color-nightrider;
					@include font-family($font-regular);
					text-transform:capitalize;
					margin:0 0 9px -1px;
					[dir="rtl"] & {margin:0 -1px 9px 0;}
				}
				.download-file {
					[class*="item"] {
						position:relative;
						margin-bottom:9px;
					}
					a {
						display:block;
						white-space:nowrap;
						color:$color-dimgray;
						padding-left:25px;
					}
					a:before {
						content: "";
						position:absolute;
						top:1px; left:0;
						width:16px;
						height:20px;
						background: {
							repeat:no-repeat;
							position:0 0;
						}
					}
					a:after {
						content: "";
						display:inline-block;
						vertical-align:middle;
						width:11px;
						height:12px;
						background: {
							image : url(/lg5-common-gp/images/common/icons/download-dimgray.svg);
							repeat:no-repeat;
							position:0 0;
						}
						margin-left:6px;
						[dir="rtl"] & {
							margin-left:0;
							margin-right:6px;
						}
					}
					a > span {
						display:inline-block;
						vertical-align:middle;
						max-width:178px;
						text-overflow:ellipsis;
						overflow:hidden;
						white-space:nowrap;
						text-transform:initial;
						text-decoration:underline;
					}
					// variation
					.html a:before {background-image:url(/lg5-common-gp/images/common/icons/file-html.png);}
					.pdf a:before {background-image:url(/lg5-common-gp/images/common/icons/file-pdf.png);}
					.dwg a:before {background-image:url(/lg5-common-gp/images/common/icons/file-dwg.png);}
					.zip a:before {background-image:url(/lg5-common-gp/images/common/icons/file-zip.png);}
					.djvu a:before {background-image:url(/lg5-common-gp/images/common/icons/file-djvu.png);}
					.etc a:before {background-image:url(/lg5-common-gp/images/common/icons/file-etc.png);}
					.img a:before {background-image:url(/lg5-common-gp/images/common/icons/file-img.png);}
					.txt a:before {background-image:url(/lg5-common-gp/images/common/icons/file-txt.png);}
				}
				.btn-dowload-set-close {
					width:20px; height:20px;
					border:none;
					background: {
						color:transparent;
						image:url(/lg5-common-gp/images/common/icons/popover-close.svg);
						repeat:no-repeat;
						position:50% 50%;
					}
					cursor:pointer;
					position:absolute;
					top:8px;
					right:8px;
					[dir="rtl"] & {
						right:auto;
						left:8px;
					}
				}
			}
			.link-download {
				&:before {
					@include pseudo();
					position:absolute;
					left:0;
					top:0;
					[dir="rtl"] & {
						left:auto;
						right:0;
					}
					width:20px;
					height:20px;
					background:url('/lg5-common-gp/images/common/icons/download-large.svg') no-repeat 0 0;
				}
				@include screen(custom, max, $max-sm) {
					padding-bottom:20px;
				}
			}
			.page-print {
				&:before {
					@include pseudo();
					position:absolute;
					left:0;top:0;
					[dir="rtl"] & {
						left:auto;
						right:0;
					}
					width:20px;
					height:20px;
					background:url('/lg5-common-gp/images/common/icons/print-dimgray.svg') no-repeat 0 0;
				}
				
			}
		}
		@include screen(custom, max, $max-sm) {
			.no-download + .sns-area .list {
				right:-156px;
				&:before {
					right:172px;
				}
			}
		}
		.sns-area {
			display:inline-block;
			position:relative;
			margin:0 0 0 22px;
			[dir="rtl"] & {
				margin:0 22px 0 0;
			}
			@include screen(custom, max, $max-sm) {
				margin:0;
				padding-bottom:20px;
				[dir="rtl"] & {
					margin:0;
				}

			}
		}
	}

	.date {
		margin: 10px 0 0 0;
		font-size:14px;
		line-height: 20px;
		color: $color-gray;
		[dir="rtl"] & {
			text-align:right;
		}
		@include screen(custom, max, $max-sm) {
			@include font-family($font-semibold);
		}
	}
	.contents {
		// 20200402 START 이상현 - list detail 컴포넌트 전체 : 본문의 여백 수정
		// variable :: padding left, right 
		$fixed : 30px;
		$protage : (130px/1284px) * 100%;

		padding: 34px $protage 44px $fixed;
		font-size: 16px;
		line-height: 24px;
		color: $color-dimgray;
		text-align: left;
		@include screen(lg) {
			padding: 34px ($fixed*2) 44px $fixed;
		}
		@include screen(md){
			padding: 34px $fixed 44px;
		}
		@include screen(custom, max, $max-sm) {
			padding: 24px 0 34px 0;
		}
		[dir="rtl"] & {
			text-align:right;
			@include screen(custom, min, $min-xl) {
				padding: 34px $fixed 44px $protage;
			}
			@include screen(lg) {
				padding: 34px $fixed 44px ($fixed*2);
			}
		}
		// 20200402 END
		p {margin:0;}
		img {max-width: 100%;}
		b, strong {
			@include font-family($font-semibold);
		}
	}

	.article-nav {
		word-break:normal;
		word-wrap:normal;
		display:table;
		margin-top:30px;
		border-top:1px solid $line-gray;
		[dir="rtl"] & {text-align:right;}
		.nav-button {
			display:table-row;
			//START : WA_CS Modify | 1.3.2 : css 수정
			@extend %listreset;
			dt, dd {
				display:table-cell;
				vertical-align:middle;
				border-bottom:1px solid $line-gray;
			}
			// border
			&:first-child {
				dt, dd {border-bottom:1px solid #eee;}
			}
			dt{
				position: relative;
				min-width:78px;
				@include screen(custom, min, $min-md) {
					padding-left:30px;
					[dir="rtl"] & {
						padding-left:0;
						padding-right:30px;
					}					
				}
				&:after {
					right:20px;
					top:50%;
					width:1px;
					height:15px;
					background:$bg-nobel;
					transform:translate(0%, -50%);
					-moz-transform:translate(0%, -50%);
					-ms-transform:translate(0%, -50%);
					-o-transform:translate(0%, -50%);
					@include pseudo();
					@include screen(custom, max, $max-sm) {
						right:15px;
					}
					[dir="rtl"] & {
						right:auto;
						left:20px;
						@include screen(custom, max, $max-sm) {
							left:15px;
						}
					}
				}
			}
			dd{
				width:100%;
				@extend %listreset;
			}
			//END : WA_CS Modify | 1.3.2 : css 수정
			span {
				display:inline-block;
				font-size:16px;
				line-height:18px;
				color:$color-nightrider;
				@include font-family($font-semibold);
				padding:14px 20px 14px 0;
				margin-right:20px;
				@include screen(custom, max, $max-sm) {
					padding:19px 15px 20px 0;
					margin-right:15px;
				}
				[dir="rtl"] & {
					padding:14px 0 14px 20px;
					margin-right:0;
					margin-left:20px;
					@include screen(custom, max, $max-sm) {
						padding:19px 0 20px 15px;
						margin-left:15px;
					}
				}
			}
			a {
				display:block;
				//START : WA_CS Modify | 1.3.2 : css 수정
				width:100%;
				//END : WA_CS Modify | 1.3.2 : css 수정
				box-sizing: content-box;
				font-size:16px;
				line-height:28px;
				@include font-family($font-regular);
				color:$color-dimgray;
				overflow:hidden;
				text-overflow: ellipsis;
				display:-webkit-box;
				-webkit-box-orient:vertical;
				-webkit-line-clamp:1;
				max-height:28px * 1;
				// 20200608 START 박지영 - RTL 에서 괄호로 끝나는 경우 깨지는 현상 수정
				[dir="rtl"] & {
					display:block;
				}
				// 20200608 END
				@include screen(custom, max, $max-sm) {
					//START : WA_CS Modify | 1.3.2 : 주석 처리
					// width:95%;
					line-height:18px;
					-webkit-line-clamp:2;
					max-height:18px * 2;
				}
			}
		}
	}
}
